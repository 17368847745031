<template>
    <div>
        <ts-page-title
            :title="$t('shopCompensation.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('shopCompensation.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper>
                <div class="tw-flex tw-justify-between tw-items-center">
                    <a-button
                        type="primary"
                        @click="addNew"
                        v-if="$can('create-shop-compensations')"
                    >
                        {{ $t("addNew") }}
                    </a-button>
                </div>
            </ts-panel-wrapper>
            <a-tabs :default-active-key="active_tab" @change="onTabChange">
                <a-tab-pane
                    :key="2"
                    :tab="$t('shopCompensation.noneFood')"
                    force-render
                >
                    <NoneFood ref="nonefood" />
                </a-tab-pane>
                <a-tab-pane
                    :key="1"
                    :tab="$t('shopCompensation.food')"
                    force-render
                >
                    <ClearanceHistory ref="food" />
                </a-tab-pane>
            </a-tabs>
        </ts-panel>
    </div>
</template>

<script>
import ClearanceHistory from "./components/clearance-history.vue";
import NoneFood from "./components/none-food.vue";
export default {
    name: "shopCompensateIndex",
    components: {
        ClearanceHistory,
        NoneFood
    },
    data() {
        return {
            active_tab: 2
        };
    },
    methods: {
        addNew() {
            this.$router.push({ name: "shop-compensation-create" });
        },
        onTabChange(index) {
            this.$store.commit("finance/shopCompensation/RESET_STATE");
            if (index == 1) {
                this.$refs.food.fetchData();
            }
            if (index == 2) {
                this.$refs.nonefood.fetchData();
            }
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.$refs.nonefood.fetchData();
        });
    }
};
</script>
