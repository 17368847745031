<template>
    <div class="tw-overflow-scroll">
        <table class="tw-w-full">
            <thead>
                <tr class="tw-bg-blue-400 tw-text-white">
                    <th
                        class="tw-whitespace-nowrap tw-uppercase tw-px-2 tw-py-1 tw-border"
                    >
                        {{ $t("numeric") }}
                    </th>
                    <th
                        class="tw-whitespace-nowrap tw-uppercase tw-px-2 tw-py-1 tw-border"
                    >
                        {{ $t("shopCompensation.dateTransaction") }}
                    </th>
                    <th
                        class="tw-whitespace-nowrap tw-uppercase tw-px-2 tw-py-1 tw-border"
                    >
                        {{ $t("shopCompensation.serviceType") }}
                    </th>
                    <th
                        class="tw-whitespace-nowrap tw-uppercase tw-px-2 tw-py-1 tw-border"
                    >
                        {{ $t("shopCompensation.receiverPhone") }}
                    </th>
                    <th
                        class="tw-whitespace-nowrap tw-uppercase tw-px-2 tw-py-1 tw-border"
                    >
                        {{ $t("shopCompensation.receiverLocation") }}
                    </th>
                    <th
                        class="tw-whitespace-nowrap tw-uppercase tw-px-2 tw-py-1 tw-border tw-text-right"
                    >
                        {{ $t("shopCompensation.codAmount") }}
                    </th>
                    <th
                        class="tw-whitespace-nowrap tw-uppercase tw-px-2 tw-py-1 tw-border tw-text-right"
                    >
                        {{ $t("shopCompensation.packagingFee") }}
                    </th>
                    <th
                        class="tw-whitespace-nowrap tw-uppercase tw-px-2 tw-py-1 tw-border"
                    >
                        {{ $t("shopCompensation.extraFee") }}
                    </th>
                    <th
                        class="tw-whitespace-nowrap tw-uppercase tw-px-2 tw-py-1 tw-border"
                    >
                        {{ $t("shopCompensation.deliveryFee") }}
                    </th>
                    <th
                        class="tw-whitespace-nowrap tw-uppercase tw-px-2 tw-py-1 tw-border"
                    >
                        {{ $t("shopCompensation.taxiFee") }}
                    </th>
                    <th
                        class="tw-whitespace-nowrap tw-uppercase tw-px-2 tw-py-1 tw-border tw-text-right"
                    >
                        {{ $t("shopCompensation.totalFee") }}
                    </th>
                    <th
                        class="tw-whitespace-nowrap tw-uppercase tw-px-2 tw-py-1 tw-border tw-text-right"
                    >
                        {{ $t("shopCompensation.amountToBeClear") }}
                    </th>
                    <th
                        class="tw-whitespace-nowrap tw-uppercase tw-px-2 tw-py-1 tw-border tw-text-right"
                    >
                        {{ $t("shopCompensation.codUSD") }}
                    </th>
                    <th
                        class="tw-whitespace-nowrap tw-uppercase tw-px-2 tw-py-1 tw-border tw-text-right"
                    >
                        {{ $t("shopCompensation.codKHR") }}
                    </th>
                    <th
                        class="tw-whitespace-nowrap tw-uppercase tw-px-2 tw-py-1 tw-border tw-text-right"
                    >
                        {{ $t("shopCompensation.fxRate") }}
                    </th>
                    <th
                        class="tw-whitespace-nowrap tw-uppercase tw-px-2 tw-py-1 tw-border"
                    >
                        {{ $t("shopCompensation.feePaidBy") }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(ep, index) in value.details" :key="index">
                    <td class="tw-whitespace-nowrap tw-py-1 tw-border tw-px-2 tw-text-center">
                        {{ index + 1 }}
                    </td>
                    <td class="tw-whitespace-nowrap tw-py-1 tw-border tw-px-2">
                        {{ ep.transaction_date }}
                    </td>
                    <td class="tw-text-left tw-border tw-py-1 tw-px-2">
                        {{ ep.service_type }}
                    </td>
                    <td class="tw-text-left tw-border tw-py-1 tw-px-2">
                        {{ ep.receiver_phone }}
                    </td>
                    <td class="tw-text-left tw-border tw-py-1 tw-px-2">
                        {{ ep.receiver_location }}
                    </td>
                    <td
                        class="tw-text-right tw-whitespace-nowrap tw-border tw-py-1 tw-px-2"
                    >
                        <ts-accounting-format
                            style="border:none"
                            :digit="2"
                            :value="ep.cod_amount"
                        />
                    </td>
                    <td
                        class="tw-text-right tw-whitespace-nowrap tw-border tw-py-1 tw-px-2"
                    >
                        <ts-accounting-format
                            style="border:none"
                            :digit="2"
                            :value="ep.packaging_fee"
                        />
                    </td>
                    <td
                        class="tw-text-right tw-whitespace-nowrap tw-border tw-py-1 tw-px-2"
                    >
                        <ts-accounting-format
                            style="border:none"
                            :digit="2"
                            :value="ep.extra_fee"
                        />
                    </td>
                    <td
                        class="tw-text-right tw-whitespace-nowrap tw-border tw-py-1 tw-px-2"
                    >
                        <ts-accounting-format
                            style="border:none"
                            :digit="2"
                            :value="ep.delivery_fee"
                        />
                    </td>
                    <td
                        class="tw-text-right tw-whitespace-nowrap tw-border tw-py-1 tw-px-2"
                    >
                        <ts-accounting-format
                            currency="KHR"
                            :digit="0"
                            :value="ep.tax_fee"
                        />
                    </td>
                    <td class="tw-text-right tw-border tw-py-1 tw-px-2">
                        <ts-accounting-format
                            :digit="2"
                            :value="ep.total_fee"
                        />
                    </td>
                    <td
                        class="tw-text-right tw-font-semibold tw-text-blue-500 tw-border tw-py-1 tw-px-2"
                    >
                        <ts-accounting-format
                            :digit="2"
                            :value="ep.amount_tobe_clear"
                        />
                    </td>
                    <td
                        class="tw-text-right tw-whitespace-nowrap tw-border tw-py-1 tw-px-2"
                    >
                        <ts-accounting-format
                            :digit="2"
                            :value="ep.cod_amount_usd_cleared"
                        />
                    </td>
                    <td
                        class="tw-text-right tw-whitespace-nowrap tw-border tw-py-1 tw-px-2"
                    >
                        <ts-accounting-format
                            currency="KHR"
                            :digit="0"
                            :value="ep.cod_amount_khr_cleared"
                        />
                    </td>
                    <td
                        class="tw-text-right tw-whitespace-nowrap tw-border tw-py-1 tw-px-2"
                    >
                        <ts-accounting-format
                            currency="KHR"
                            :digit="0"
                            :value="ep.fx_rate"
                        />
                    </td>
                    <td class="tw-border tw-py-1 tw-px-2">
                        {{ ep.fee_paid_by }}
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td colspan="13" class="tw-text-right tw-px-3 tw-uppercase">
                        <strong>{{ $t("shopCompensation.codAmount") }} : </strong>
                    </td>
                    <td class="tw-px-3" colspan="2">
                        <ts-accounting-format
                            :digit="2"
                            :value="sumTotal('cod_amount')"
                        />
                    </td>
                </tr>
                <tr>
                    <td colspan="13" class="tw-text-right tw-px-3 tw-uppercase">
                        <strong>{{ $t("shopCompensation.extraFee") }} : </strong>
                    </td>
                    <td class="tw-px-3" colspan="2">
                        <ts-accounting-format
                            :digit="2"
                            :value="sumTotal('extra_fee')"
                        />
                    </td>
                </tr>
                <tr>
                    <td colspan="13" class="tw-text-right tw-px-3 tw-uppercase">
                        <strong>{{ $t("shopCompensation.taxiFee") }} : </strong>
                    </td>
                    <td class="tw-px-3" colspan="2">
                        <ts-accounting-format
                            currency="KHR"
                            :digit="0"
                            :value="sumTotal('tax_fee')"
                        />
                    </td>
                </tr>
								<tr>
                    <td colspan="13" class="tw-text-right tw-px-3 tw-uppercase">
                        <strong>{{ $t("shopCompensation.deliveryFee") }} : </strong>
                    </td>
                    <td class="tw-px-3" colspan="2">
                        <ts-accounting-format
                            :digit="2"
                            :value="sumTotal('delivery_fee')"
                        />
                    </td>
                </tr>
								<tr>
                    <td colspan="13" class="tw-text-right tw-px-3 tw-uppercase">
                        <strong>{{ $t("shopCompensation.amountToBeClear") }} : </strong>
                    </td>
                    <td class="tw-px-3" colspan="2">
                        <ts-accounting-format
                            :digit="2"
                            :value="sumTotal('amount_tobe_clear')"
                        />
                    </td>
                </tr>
								<tr>
                    <td colspan="13" class="tw-text-right tw-px-3 tw-uppercase">
                        <strong>{{ $t("shopCompensation.codUSD") }} : </strong>
                    </td>
                    <td class="tw-px-3" colspan="2">
                        <ts-accounting-format
                            :digit="2"
                            :value="sumCashInDollar"
                        />
                    </td>
                </tr>
								<tr>
                    <td colspan="13" class="tw-text-right tw-px-3 tw-uppercase">
                        <strong>{{ $t("shopCompensation.codKHR") }} : </strong>
                    </td>
                    <td class="tw-px-3" colspan="2">
                        <ts-accounting-format
														currency="KHR"
                            :digit="0"
                            :value="sumCashInKhr"
                        />
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>
</template>

<script>
import { sumBy } from "lodash";

export default {
    name: "showViewDetail",
    props: ["value"],
    data() {
        return {};
    },
    computed: {
        sumTotal: vm => column => {
            var amount = sumBy(vm.value.details, column);
            return amount;
        },
				sumCashInDollar: vm => {
						return sumBy(vm.value.details, 'cod_amount_usd_cleared') < 0 ? 0 : sumBy(vm.value.details, 'cod_amount_usd_cleared');
				},
				sumCashInKhr: vm => {
					// sumTotal('cod_amount_khr_cleared')
					if(sumBy(vm.value.details, 'cod_amount_usd_cleared') < 0 && sumBy(vm.value.details, 'cod_amount_khr_cleared') > 0)
					{
						let exchange_dollar = (sumBy(vm.value.details, 'cod_amount_usd_cleared') * 4000) * -1
						return sumBy(vm.value.details, 'cod_amount_khr_cleared') - exchange_dollar 
					}

					return sumBy(vm.value.details, 'cod_amount_khr_cleared')
				}
    }
};
</script>
