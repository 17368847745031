<template>
    <div>
        <div class="tw-flex tw-justify-between tw-items-center tw-p-4">
            <div class="tw-flex tw-items-center tw-space-x-3">
                <a-range-picker
                    v-model="dateRange"
                    :allowClear="false"
                    format="DD-MM-YYYY"
                    valueFormat="DD-MM-YYYY"
                    style="width: 200px"
                    @change="fetchData()"
                />
                <a-radio-group v-model="is_clear" @change="fetchData()">
                    <a-radio-button :value="null">
                        {{ $t("shopCompensation.all") }}
                    </a-radio-button>
                    <a-radio-button :value="1">
                        {{ $t("shopCompensation.cleared") }}
                    </a-radio-button>
                    <a-radio-button :value="0">
                        {{ $t("shopCompensation.noClear") }}
                    </a-radio-button>
                </a-radio-group>
                <a-radio-group v-model="account_filter" @change="fetchData()">
                    <a-radio-button :value="null">
                        {{ $t("shopCompensation.all") }}
                    </a-radio-button>
                    <a-radio-button :value="1">
                        {{ $t("shopCompensation.accountReceiveAble") }}
                    </a-radio-button>
                    <a-radio-button :value="0">
                        {{ $t("shopCompensation.accountPayAble") }}
                    </a-radio-button>
                </a-radio-group>
                <a-select
                    v-if="$whois.admin()"
                    v-model="agency_id"
                    style="width: 220px"
                    :options="agencies"
                    showSearch
                    mode="multiple"
                    placeholder="All Agency"
                    :max-tag-count="1"
                    :max-tag-text-length="7"
                    :filterOption="
                        (input, option) =>
                            option.componentOptions.children[0].text
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                    "
                    @change="fetchData()"
                >
                </a-select>
                <div class="tw-space-x-3 tw-flex">
                    <ts-button
                        :disabled="!countItemList > 0"
                        @click.prevent="onPreviewOnly"
                        color="primary"
                        outline
                        >{{ $t("shopCompensation.preview") }}</ts-button
                    >

                    <ts-button
                        v-if="$whois.admin()"
                        :disabled="!countItemList > 0"
                        @click.prevent="onClearOrPreviewMulti"
                        color="danger"
                        outline
                        >{{ $t("shopCompensation.clear") }}</ts-button
                    >
                    <ts-button
                        v-if="$whois.admin()"
                        :disabled="!countItemList > 0"
                        @click.prevent="sendToTelegram"
                        color="primary"
                        outline
                        ><i class="fab fa-telegram"></i
                    ></ts-button>
                </div>
            </div>
            <div class="tw-space-x-3">
                <a-tooltip :title="$t('shopCompensation.search')">
                    <a-input-search
                        v-model="search"
                        :placeholder="$t('shopCompensation.search')"
                        style="width: 200px"
                    />
                </a-tooltip>
            </div>
        </div>
        <div class="tw-overflow-x-scroll">
            <ts-table :loading="loading">
                <template v-slot:thead>
                    <thead class="tw-bg-blue-500 tw-text-white">
                        <tr>
                            <th
                                class="tw-px-6 tw-py-3 tw-text-left tw-uppercase tw-whitespace-nowrap page-header tw-border tw-border-gray-400"
                            >
                                <div
                                    class="tw-flex tw-items-center tw-mb-1 tw-ml-2"
                                >
                                    <input
                                        id="default-checkbox"
                                        type="checkbox"
                                        :checked="isCheckAll"
                                        class="tw-w-4 tw-h-4 tw-text-blue-600 tw-bg-gray-100focus:tw-ring-blue-500 focus:tw-ring-2"
                                        @change="OnCheckAll"
                                    />
                                    <!-- <label for="default-checkbox" class="tw-ml-2 tw-text-sm tw-font-medium tw-text-gray-900">Default checkbox</label> -->
                                </div>
                            </th>
                            <template v-for="(column, key) in columns">
                                <th
                                    :key="key"
                                    class="tw-px-6 tw-py-3 tw-text-left tw-uppercase tw-whitespace-nowrap page-header tw-border tw-border-gray-400"
                                    :style="column.style"
                                >
                                    {{ column.name }}
                                </th>
                            </template>
                        </tr>
                        <TableProgressBar
                            v-show="loading"
                            :columnLength="columns.length"
                        />
                    </thead>
                </template>
                <template v-slot:tbody>
                    <tbody>
                        <tr v-for="(record, index) in resources" :key="index">
                            <td>
                                <div
                                    class="tw-flex tw-items-center tw-mb-1 tw-ml-2"
                                >
                                    <input
                                        v-if="forceRender"
                                        type="checkbox"
                                        v-model="checkedList"
                                        :value="record.shop_id_date"
                                        class="tw-w-4 tw-h-4 tw-text-blue-600 tw-bg-gray-100 focus:tw-ring-blue-500 focus:tw-ring-2"
                                    />
                                    <!-- <label for="default-checkbox" class="tw-ml-2 tw-text-sm tw-font-medium tw-text-gray-900">Default checkbox</label> -->
                                </div>
                            </td>
                            <td class="tw-text-center">{{ index + 1 }}</td>
                            <td class="tw-whitespace-nowrap">{{ record.transaction_date }}</td>
                            <td class="tw-whitespace-nowrap">
                                {{ record.shop_clear_date }}
                            </td>
                            <td class="tw-whitespace-nowrap">
                                {{ record.shop_name }}
                            </td>
                            <td class="tw-whitespace-nowrap">
                                {{ record.tel_chat_id }}
                            </td>
                            <td class="tw-whitespace-nowrap">
                                {{ record.agency_name }}
                            </td>
                            <td class="tw-text-center">
                                {{ record.details.length }}
                            </td>
                            <td class="tw-text-right">
                                <ts-accounting-format
                                    :digit="2"
                                    :value="record.cod_amount"
                                />
                            </td>
                            <td class="tw-text-right">
                                <ts-accounting-format
                                    :digit="2"
                                    :value="record.delivery_fee"
                                />
                            </td>
                            <td class="tw-text-right">
                                <ts-accounting-format
                                    :digit="2"
                                    :value="record.packaging_fee"
                                />
                            </td>
                            <td class="tw-text-right">
                                <ts-accounting-format
                                    :digit="2"
                                    :value="record.extra_fee"
                                />
                            </td>
                            <td class="tw-text-right">
                                <ts-accounting-format
                                    :digit="2"
                                    :value="record.total_fee"
                                />
                            </td>
                            <td
                                class="tw-text-right tw-font-semibold tw-text-blue-500"
                            >
                                <ts-accounting-format
                                    :digit="2"
                                    :value="record.amount_tobe_clear"
                                />
                            </td>
                            <td class="tw-text-right">
                                <ts-accounting-format
                                    :digit="2"
                                    :value="record.cod_amount_usd_cleared"
                                />
                            </td>
                            <td class="tw-text-right">
                                <ts-accounting-format
                                    currency="KHR"
                                    :digit="0"
                                    :value="record.cod_amount_khr_cleared"
                                />
                            </td>
                            <td class="tw-text-right">
                                <ts-accounting-format
                                    currency="KHR"
                                    :digit="0"
                                    :value="
                                        record.amount_tobe_collected_khr -
                                            record.amount_collected_khr
                                    "
                                />
                            </td>
                            <td class="tw-text-center">
                                <a-badge
                                    v-if="record.is_shop_clear"
                                    count="Yes"
                                    :number-style="{
                                        backgroundColor: '#52c41a'
                                    }"
                                />
                                <a-badge v-else count="No" />
                            </td>
                            <td>{{ record.shop_clear_by }}</td>
                            <td
                                class="tw-whitespace-nowrap tw-text-center tw-space-x-2"
                            >
                                <a
                                    href="#"
                                    class="tw-text-blue-700"
                                    @click.prevent="showDetail(record)"
                                >
                                    <a-tooltip placement="left">
                                        <template v-slot:title>{{
                                            $t("view")
                                        }}</template>
                                        <i class="fas fa-eye"></i>
                                    </a-tooltip>
                                </a>
                                <a href="#" @click.prevent="previewPdf(record)">
                                    <a-tooltip
                                        placement="left"
                                        v-if="record.is_shop_clear"
                                    >
                                        <template v-slot:title>{{
                                            $t("pdf")
                                        }}</template>
                                        <i
                                            class="fas fa-file-pdf tw-text-red-500"
                                        ></i>
                                    </a-tooltip>
                                    <a-tooltip placement="left" v-else>
                                        <template v-slot:title>{{
                                            $t("clearance")
                                        }}</template>
                                        <i
                                            class="fas fa-hand-holding-usd"
                                            v-if="$whois.admin()"
                                        ></i>
                                    </a-tooltip>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </template>
                <template v-slot:tfooter>
                    <tr>
                        <td
                            colspan="8"
                            class="tw-text-right tw-uppercase tw-font-semibold tw-py-1 tw-px-2 tw-border"
                        >
                            {{ $t("shopCompensation.total") }} :
                        </td>
                        <td
                            class="tw-text-right tw-font-semibold tw-text-blue-500 tw-border"
                        >
                            <ts-accounting-format
                                style="border: none"
                                :digit="2"
                                :value="sumTotal('cod_amount')"
                            />
                        </td>
                        <td
                            class="tw-text-right tw-font-semibold tw-text-blue-500 tw-border"
                        >
                            <ts-accounting-format
                                style="border: none"
                                :digit="2"
                                :value="sumTotal('delivery_fee')"
                            />
                        </td>
                        <td
                            class="tw-text-right tw-font-semibold tw-text-blue-500 tw-border"
                        >
                            <ts-accounting-format
                                style="border: none"
                                :digit="2"
                                :value="sumTotal('packaging_fee')"
                            />
                        </td>
                        <td
                            class="tw-text-right tw-font-semibold tw-text-blue-500 tw-border"
                        >
                            <ts-accounting-format
                                style="border: none"
                                :digit="2"
                                :value="sumTotal('extra_fee')"
                            />
                        </td>
                        <td
                            class="tw-text-right tw-font-semibold tw-text-blue-500 tw-border"
                        >
                            <ts-accounting-format
                                style="border: none"
                                :digit="2"
                                :value="sumTotal('total_fee')"
                            />
                        </td>
                        <td
                            class="tw-text-right tw-font-semibold tw-text-blue-500"
                        >
                            <ts-accounting-format
                                style="border: none"
                                :digit="2"
                                :value="sumTotal('amount_tobe_clear')"
                            />
                        </td>
                        <td
                            class="tw-text-right tw-font-semibold tw-text-blue-500 tw-border"
                        >
                            <ts-accounting-format
                                style="border: none"
                                :digit="2"
                                :value="sumTotal('cod_amount_usd_cleared')"
                            />
                        </td>
                        <td
                            class="tw-text-right tw-font-semibold tw-text-blue-500 tw-border"
                        >
                            <ts-accounting-format
                                style="border: none"
                                currency="KHR"
                                :digit="0"
                                :value="sumTotal('cod_amount_khr_cleared')"
                            />
                        </td>
                        <td
                            class="tw-text-right tw-font-semibold tw-text-blue-500 tw-border"
                        >
                            <ts-accounting-format
                                style="border: none"
                                currency="KHR"
                                :digit="0"
                                :value="
                                    sumTotal('amount_tobe_collected_khr') -
                                        sumTotal('amount_collected_khr')
                                "
                            />
                        </td>
                    </tr>
                </template>
            </ts-table>
        </div>
        <div class="d-flex justify-content-end tw-p-4">
            <ts-pagination
                v-model="pagination"
                @navigate="fetchData"
            ></ts-pagination>
        </div>
        <a-modal
            :title="$t('pdf')"
            width="50%"
            v-model="preview_pdf"
            :footer="null"
        >
            <ts-preview-pdf v-model="waiting" :src="src" />
        </a-modal>
        <a-modal
            :title="show_detail_record.shop_name"
            width="90%"
            v-model="show_detail"
            :footer="null"
            :centered="true"
            :zIndex="1020"
        >
            <ShowDetails :value="show_detail_record" />
        </a-modal>
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { debounce, sumBy } from "lodash";
// import Details from "./detail.vue";
import ShowDetails from "./show-view-detail.vue";
import TableProgressBar from "@/components/common/TsTable/TableProgressBar";

export default {
    name: "shopCompensateHistory",
    components: {
        // Details,
        TableProgressBar,
        ShowDetails
    },
    data() {
        return {
            show_detail: false,
            preview_pdf: false,
            waiting: false,
            src: "",
            loading: false,
            forceRender: true,
            checkedList: [],
            show_detail_record: {},
            agencies: []
        };
    },
    created() {
        this.onFetchModel();
    },
    computed: {
        ...mapState("finance/shopCompensation", ["resources", "pagination"]),
        agency_id: {
            get() {
                return this.$store.state.finance.shopCompensation.agency_id;
            },
            set(newValue) {
                this.$store.commit(
                    "finance/shopCompensation/SET_SERVICE_AGENCY",
                    newValue
                );
            }
        },
        search: {
            get() {
                return this.$store.state.finance.shopCompensation.search;
            },
            set(newValue) {
                this.$store.commit(
                    "finance/shopCompensation/SET_SEARCH",
                    newValue
                );
                this.$store.commit(
                    "finance/shopCompensation/RESET_CURRENT_PAGE"
                );
            }
        },
        dateRange: {
            get() {
                return this.$store.state.finance.shopCompensation.dateRange;
            },
            set(newValue) {
                this.$store.commit(
                    "finance/shopCompensation/SET_DATE_RANGE",
                    newValue
                );
            }
        },
        columns() {
            return [
                { name: this.$t("shopCompensation.no") },
                { name: this.$t("shopCompensation.dateTransaction") },
                { name: this.$t("shopCompensation.clearDate") },
                { name: this.$t("shopCompensation.shopName") },
                { name: this.$t("shopCompensation.telegramChat") },
                { name: this.$t("shopCompensation.agencyName") },
                { name: this.$t("shopCompensation.numberPackage") },
                {
                    name: this.$t("shopCompensation.codAmount"),
                    style: "text-align:right"
                },
                {
                    name: this.$t("shopCompensation.deliveryFee"),
                    style: "text-align:right"
                },
                {
                    name: this.$t("shopCompensation.packagingFee"),
                    style: "text-align:right"
                },
                {
                    name: this.$t("shopCompensation.extraFee"),
                    style: "text-align:right"
                },
                {
                    name: this.$t("shopCompensation.totalFee"),
                    style: "text-align:right"
                },
                {
                    name: this.$t("shopCompensation.amountToBeClear"),
                    style: "text-align:right"
                },
                {
                    name: this.$t("shopCompensation.codUSD"),
                    style: "text-align:right"
                },
                {
                    name: this.$t("shopCompensation.codKHR"),
                    style: "text-align:right"
                },
                {
                    name: this.$t("shopCompensation.margin"),
                    style: "text-align:right"
                },
                {
                    name: this.$t("shopCompensation.isClear"),
                    style: "text-align:center"
                },
                { name: this.$t("shopCompensation.clearBy") },
                { name: this.$t("actions") }
            ];
        },
        sumTotal: vm => column => {
            var amount = sumBy(vm.resources, column);

            return amount;
        },
        is_clear: {
            get() {
                return this.$store.state.finance.shopCompensation.is_clear;
            },
            set(newValue) {
                this.$store.commit(
                    "finance/shopCompensation/SET_IS_CLEARED",
                    newValue
                );
            }
        },
        account_filter: {
            get() {
                return this.$store.state.finance.shopCompensation
                    .account_filter;
            },
            set(newValue) {
                this.$store.commit(
                    "finance/shopCompensation/SET_ACCOUNT_FILTER",
                    newValue
                );
            }
        },
        currentPage() {
            if (this.pagination) {
                return this.pagination.currentPage - 1;
            }

            return 0;
        },
        isCheckAll() {
            if (this.checkedList != undefined) {
                if (this.checkedList.length) {
                    return this.checkedList.length == this.resources.length;
                }
            }
            return false;
        },
        countItemList() {
            return this.checkedList.length;
        }
    },
    methods: {
        ...mapActions("finance/shopCompensation", [
            "toPdf",
            "store",
            "clearPreview",
            "getFormViewData",
            "sentToTelegram"
        ]),
        fetchData(attributes) {
            this.loading = true;
						this.$store.commit('finance/shopCompensation/SET_BUSINESS_CAT', 2) // none-food
            this.$store
                .dispatch("finance/shopCompensation/fetch", { ...attributes })
                .then(() => {
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.$notify({ type: "error", text: error.message });
                });
        },
        previewPdf(record) {
            if (!record.is_shop_clear && this.$whois.admin()) {
                this.clearance(record);
            } else {
                this.onlyPdf(record);
            }
        },
        clearance(record) {
            this.preview_pdf = true;
            this.waiting = true;
            this.store({
                date_transaction: record.transaction_date,
                shop_id: record.shop_id,
                remark: null
            })
                .then(response => {
                    this.$notify({ type: "success", text: response.message });
                    this.src = response.url;
                    this.fetchData();
                })
                .catch(error => {
                    this.preview_pdf = false;
                    this.$notify({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting = false;
                });
        },
        onlyPdf(record) {
            this.preview_pdf = true;
            this.waiting = true;
            this.toPdf(record.shop_comp_clear_id)
                .then(response => {
                    this.src = response.url;
                })
                .catch(error => {
                    this.$notify({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting = false;
                });
        },
        showDetail(record) {
            this.show_detail = true;
            this.show_detail_record = record;
        },
        OnCheckAll(e) {
            this.forceRender = false;
            if (e.target.checked) {
                this.checkedList = this.resources.map(el => el.shop_id_date);
            } else {
                this.checkedList = [];
            }
            this.$nextTick(() => {
                this.forceRender = true;
                this.$forceUpdate();
            });
        },
        onClearOrPreviewMulti() {
            this.preview_pdf = true;
            this.waiting = true;
            this.clearPreview({
                item: this.checkedList,
                transaction_date: this.resources[0]["transaction_date"],
                is_clear: true
            })
                .then(response => {
                    this.src = response.url;
                    this.checkedList = [];
                    this.fetchData();
                })
                .catch(error => {
                    this.$notify({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting = false;
                });
        },
        onPreviewOnly() {
            this.preview_pdf = true;
            this.waiting = true;
            this.clearPreview({
                item: this.checkedList,
                transaction_date: this.resources[0]["transaction_date"],
                is_clear: false
            })
                .then(response => {
                    this.src = response.url;
                })
                .catch(error => {
                    this.$notify({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting = false;
                });
        },
        sendToTelegram() {
            this.loading = true;
            this.sentToTelegram({
                item: this.checkedList
            })
                .then(response => {
                    this.$notify({ type: "success", text: response.message });
                })
                .catch(error => {
                    this.$notify({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        onFetchModel() {
            this.getFormViewData({
                params: {
                    fnName: "agency"
                }
            })
                .then(response => {
                    this.agencies = response.data.agency.map(el => ({
                        value: el.agency_id,
                        label: el.agency_name
                    }));
                })
                .catch(error => {
                    this.$notify({ type: "error", text: error.message });
                });
        }
    },
    watch: {
        search: debounce(function() {
            this.fetchData();
        }, 500)
    }
};
</script>

<style lang="css" scoped>
.table > tbody > td {
    border: 1px solid #c7c7c7 !important;
}
</style>
